import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Nettlesere",
  "order": 1,
  "template": "default",
  "introtext": "Nettlesere designsystemet sikter på støtte"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Nettleserversjoner`}</h1>
    <p>{`Offisielt anbefaler vi brukerne våre om å alltid holde nettleseren `}<a parentName="p" {...{
        "href": "https://www.sparebank1.no/nb/bank/privat/info/anbefalte-nettlesere.html"
      }}>{`oppdatert til siste versjon`}</a>{`.`}</p>
    <p>{`Mer praktisk er det en del faktorer som påvirker hva vi skal støtte, den største er hvilke nettlesere brukerne våre benytter. Per dags dato (2025.02.02) er det:`}</p>
    <ul>
      <li parentName="ul">{`Rundt 1000 brukere med iOS 15 og Safari 15`}</li>
      <li parentName="ul">{`En del brukere på Windows 7, hvor siste versjon av Chrome er 109 fra januar 2023`}</li>
    </ul>
    <p>{`Vi skal med andre ord støtte nettlesere tilbake til `}<a parentName="p" {...{
        "href": "https://browsersl.ist/#q=since+2021-04&region=NO"
      }}>{`april 2021`}</a>{`. Rent teknisk er det 3 ting å tenke på: JS, CSS, og HTML. Kort oppsummert transpileres JS til `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ES2020`}</code>{` fra 2020, så JS vil fungere. I CSS kan noe gjøres automatisk med autoprefixing via `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`browserslist`}</code>{`, mens andre funksjoner bør unngås. Med HTML må man polyfille om man ønsker å bruke elementer som ikke er støttet. Dette gjelder stort sett `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`dialog`}</code>{` elementet.`}</p>
    <p>{`Vi kommer til å sjekke bruk og oppdatere anbefalingen gjevnlig(halvårlig ich).`}</p>
    <p>{`Sjekk `}<a parentName="p" {...{
        "href": "https://caniuse.com/?compare=chrome+109,safari+15,firefox+91,opera+86,and_chr+134,ios_saf+15.0-15.1,android+134&compareCats=all#results"
      }}>{`caniuse med query for de nettleserne vi skal støtte`}</a>{` for å se om det du ønsker å bruke er støttet, krever prefix i css eller polyfill. Det at noe ikke er støttet av en nettleser betyr ikke at en ikke kan bruke det. Men en må da tenke på om er dette noe som fremdeles vil fungere for brukere av nettlesere som ikke støtter dette, eller vil det være ødeleggende for deres opplevelse. Diskuter gjerne i #ext-frontend om det er noe du vil bruke, men som ikke er godt støttet så vil du få hjelp til å ta et valg.`}</p>
    <p>{`De neste seksjonene går mer i dybden på hva som skal til for å sette opp prosjektet til å støtte målet vi har.`}</p>
    <h2>{`JS`}</h2>
    <p>{`Vi bruker vite i prosjektene våre. Vite har `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`build.target`}</code>{` default satt til `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`['es2020', 'edge88', 'firefox78', 'chrome87', 'safari14']`}</code>{` med mindre en overstyrer dette i følge `}<a parentName="p" {...{
        "href": "https://vite.dev/config/build-options"
      }}>{`vite dokumentasjonene`}</a>{`. Sjekk `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`vite.config.ts`}</code>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`build.target`}</code>{` om du er usikker på om dere gjør det(ingen gjorde det i kundefronter per 2025.04.02).`}</p>
    <p>{`Det vil si at vi er godt innenfor det vi skal støtte med tanke på JS. Vite transpilerer JSen vi bruker til `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ES2020`}</code>{`.`}</p>
    <p>{`Det finnes og en `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`target`}</code>{` i `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`tsconfig.json`}</code>{`, men den påvirker bare hva `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`tsc`}</code>{` reagerer på når den typesjekker. Den skal ikke påvirke det som blir bygget.`}</p>
    <h2>{`CSS`}</h2>
    <p>{`Med css så er det noe som kan fikses automatisk`}</p>
    <p>{`Vår template bruker `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`postcss`}</code>{` og `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`autoprefixer`}</code>{`. Så lenge man bruker dette kan man utvide CSS-støtte ved å legge til en `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.browserslistrc`}</code>{` fil med `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`since 2021-04`}</code>{` som innhold. Sjekk `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`vite.config.ts`}</code>{` for å finne ut om ditt prosjekt bruker `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`postcss`}</code>{` og `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`autoprefixer`}</code>{`. Dette kan også legges til i `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`package.json`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{` "browserslist": [
    "since 2021-04"
  ]`}</code></pre></div>
    <p><a parentName="p" {...{
        "href": "https://browsersl.ist/#q=since+2021-04&region=NO"
      }}>{`Browserslist `}<code parentName="a" {...{
          "className": "language-text"
        }}>{`since 2021-04`}</code></a>{` - query for brukere i Norge hvis en vil sammenligne med nasjonal bruk.`}</p>
    <p>{`Det er også viktig å sjekke `}<a parentName="p" {...{
        "href": "https://caniuse.com/?compare=chrome+109,safari+15,firefox+91,opera+86,and_chr+134,ios_saf+15.0-15.1,android+134&compareCats=all#results"
      }}>{`caniuse med query for de nettleserne vi skal støtte`}</a>{`.`}</p>
    <p>{`Finner du ikke featuren du leter etter kan du også søke på den.`}</p>
    <h2>{`HTML`}</h2>
    <p>{`For HTML er det viktig å sjekke `}<a parentName="p" {...{
        "href": "https://caniuse.com/?compare=chrome+109,safari+15,firefox+91,opera+86,and_chr+134,ios_saf+15.0-15.1,android+134&compareCats=all#results"
      }}>{`caniuse med query for de nettleserne vi skal støtte`}</a>{`. Det er stort sett `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`dialog`}</code>{` som ikke er godt nok støttet og krever polyfill, om man ikke bruker modalen fra ffe som kommer med polyfillet.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      